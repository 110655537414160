import { stringify } from "qs";

const ISSUE_PAGE_SIZE = 10;

export const endpoints = {
  changeDomain: `/rest/change_domain`,
  failedInvites: {
    index: (filters: Record<string, unknown>) =>
      `/rest/failed_invites?${stringify(filters)}`,
    csv: (filters: Record<string, unknown>) =>
      `/rest/failed_invites.csv?${stringify(filters)}`,
  },
  appointments: (id: string, params?: Record<string, number | undefined>) =>
    `/rest/calendars/${id}/appointments?${stringify(params)}`,
  user: `/rest/user`,
  teams: {
    create: `/rest/hcp_team`,
    delete: (team_id: number | string) => `/rest/hcp_team/delete/${team_id}`,
    index: "/rest/hcp_team#index",
    update: (team_id: number | string) => `/rest/hcp_team/${team_id}`,
  },
  users: {
    index: (filters: Record<string, unknown>) =>
      `/rest/users?${stringify(filters)}`,
    create: "/rest/users",
    user: (id: string) => `/rest/users/${id}`,
    allConsents: (id: string) => `/rest/users/${id}/person_consents`,
    sendMobileAppSms: `/rest/users/send_mobile_app_sms`,
    sendMobileAppEmail: `/rest/users/send_mobile_app_email`,
    reset: (id: string) => `/rest/users/${id}/reset`,
    validationRules: `/rest/users/user_validation_rules`,
  },
  patient_advisory_board: {
    form_url: (id: string) =>
      `/rest/users/${id}/person_consents/patient_advisory_board_form_url`,
  },
  population_dashboard_users: (filters: Record<string, unknown>) =>
    `/rest/population_dashboard/clients?${stringify(filters)}`,
  population_dashboard_data_points: (params: Record<string, unknown>) =>
    `/rest/population_dashboard/data_points?${stringify(params)}`,
  tasks: (id?: string) => `/rest/tasks/${id || ""}`,
  dismiss_task: (id: string) => `/rest/tasks/${id}/dismiss`,
  task_actions: `/rest/task_actions`,
  invites: {
    show: (id: string) => `/rest/invites/${id}`,
    create: `/rest/invites/`,
  },
  caregiver_invites: {
    create: `/caregiver_invites/`,
  },
  client: {
    staff: (id: string, own = true) =>
      `/rest/clients/${id}/staff?own=${own.toString()}`,
    answers: (client_id: string, answerId?: string) =>
      `/rest/clients/${client_id}/answers/${answerId || ""}`,
    graphs: (id: string, field?: string) =>
      `/rest/clients/${id}/graphs/${field || ""}`,
    symptoms: (id: string, params: Record<string, unknown>) =>
      `/rest/clients/${id}/symptoms?${stringify(params)}`,
    allSymptoms: (id: string, params: Record<string, unknown> = {}) =>
      `/rest/clients/${id}/symptoms/all?${stringify(params)}`,
    symptomComparisonQuestions: (id: string) =>
      `/rest/clients/${id}/symptoms/symptom_comparison_questions`,
    symptomComparisonAnswers: (id: string) =>
      `/rest/clients/${id}/symptoms/symptom_comparison_data`,
    treatment_programs: (id: string) =>
      `/rest/clients/${id}/own_treatment_programs`,
    observations: (id: string, params: Record<string, unknown>) =>
      `/api/v1/mobile/clients/${id}/observations?${stringify(params)}`,
    taskSubscriptions: {
      index: (client_id: string) =>
        `/rest/clients/${client_id}/task_subscriptions`,
      update: (client_id: string) =>
        `/rest/clients/${client_id}/task_subscriptions`,
    },
    caregivers: {
      index: (client_id: string) => `/rest/clients/${client_id}/caregivers`,
      add: (client_id: string) => `/rest/clients/${client_id}/caregivers/`,
      removeMultiple: (client_id: string) =>
        `/rest/clients/${client_id}/caregivers`,
    },
    categoryData: (clientId: string, category: string) =>
      `/clients/${clientId}/input_form_answers/category/${category}/graph.json`,
  },
  caregivees: {
    index: `/rest/caregivees`,
    signedInUser: `/rest/caregivees/signed_in_user`,
    resetRememberMe: `/rest/caregivees/reset_remember_me`,
    switchAccount: (personId: number, rememberProfileId: number | null) => {
      if (rememberProfileId) {
        return `/rest/caregivees/switch_account/${personId}?remember_selected_profile_id=${rememberProfileId}`;
      }
      return `/rest/caregivees/switch_account/${personId}`;
    },
  },
  absences: {
    index: `/rest/absences`,
    show: (id: string) => `/rest/absences/${id}`,
    create: `/rest/absences`,
    update: (id: string) => `/rest/absences/${id}`,
    delete: (id: string) => `/rest/absences/${id}`,
  },
  clients_symptoms_meta: `/rest/clients_symptoms_meta/`,
  inputForm: (id: number | string) =>
    `/rest/input_form_answers/${id}/input_form`,
  issues: {
    assign: (id: string) => `/rest/issues/${id}/assignment`,
    done: (id: string) => `/rest/issues/${id}/done`,
    done_with_action: (id: string) => `/rest/issues/${id}/done_with_action`,
    index: (params: Record<string, unknown>) => {
      const stringifiedQueryParams = stringify(
        {
          sort: "updated_at:desc",
          "page[size]": ISSUE_PAGE_SIZE,
          ...params,
        },
        {
          arrayFormat: "brackets",
          addQueryPrefix: true,
        },
      );

      return `/rest/issues${stringifiedQueryParams}`;
    },
    stale: {
      index: `/rest/issues/stale`,
      done: `/rest/issues/stale/done`,
    },
  },
  crc_issues: {
    index: (id: string | number) => `/rest/clients/${id}/crc_module/crc_issue`,
    done_with_sms: (issueId: string, clientId: string) =>
      `/rest/clients/${clientId}/crc_module/crc_issue/${issueId}/done_with_sms`,
  },
  articles: {
    index: "/rest/articles",
    articleWithCategoryMatchAllowed: (id: string) =>
      `/rest/articles/${id}/category_id_match`,
  },
  shared_documents: {
    show: (clientId?: string) =>
      `/rest/shared_documents${clientId ? `?client_id=${clientId}` : ""}`,
    index: (id: string) => `rest/shared_documents/${id}`,
    create: "/rest/shared_documents",
    sent_by_client: (clientId: string) =>
      `/rest/shared_documents/sent_documents?client_id=${clientId}`,
  },
  data_points: (ifa_id: number) =>
    `/rest/input_form_answers/${ifa_id}/data_points`,
  section: (ifa_id: string | number, section_index: string | number) =>
    `/rest/input_form_answers/${ifa_id}/sections/${section_index}`,
  attachment: (ifa_id: string, data_point_id: string, attachment_id: string) =>
    `/rest/input_form_answers/${ifa_id}/data_points/${data_point_id}/attachment/${attachment_id}`,
  staff: "/rest/staff",
  avatar: (id: string) => `/people/${id}/avatar`,
  update_user_status: (id: string) => `/people/${id}/update_user_status`,
  conversations: {
    index: (params: Record<string, unknown> = {}) =>
      `/rest/conversations?${stringify(params)}`,
    show: (id: string) => `/rest/conversations/${id}`,
    participants: (conv: string, person?: string) =>
      `/rest/conversations/${conv}/participants/${person || ""}`,
    recent: "/rest/conversations/recent",
  },
  article_string: `/rest/instructions/article_string`,
  instructions: "/rest/instructions",
  symptom_name: "/rest/instructions/symptom_name",
  score_name: "/rest/instructions/score_name",
  generic_symptom_feedback: "/rest/instructions/generic_symptom_feedback",
  feedback: (client: string, answer_id: string) =>
    `/rest/clients/${client}/answers/${answer_id}/feedback`,
  messages: (conversation: string, message?: string) =>
    `/rest/conversations/${conversation}/messages/${message || ""}`,
  massNotifications: {
    index: (
      params: { query?: string; page?: { number: number; size?: number } } = {},
    ) => `/rest/mass_notifications/?${stringify(params)}`,
    create: `/rest/mass_notifications/`,
    show: (messageId: number) => `/rest/mass_notifications/${messageId}`,
    countRecipients: (params: {
      domains: string[];
      treatment_program_ids?: number[];
      user_type: string;
      include_clients_with_no_programs?: boolean;
    }) =>
      `/rest/mass_notifications/count_recipients?${stringify(params, {
        indices: false,
        arrayFormat: "brackets",
      })}`,
  },
  sendMessage: (conversation: string) =>
    `/rest/conversations/${conversation}/messages/send_draft`,
  draft: (conversation: string) =>
    `/rest/conversations/${conversation}/messages/draft`,
  draftAttachment: (conversation: string, attachmentId?: string) =>
    `/rest/conversations/${conversation}/messages/draft_attachment${
      attachmentId ? `/${attachmentId}` : ""
    }`,
  draftAttachments: (conversation: string, attachmentId?: string) =>
    `/rest/conversations/${conversation}/messages/draft_attachments${
      attachmentId ? `/${attachmentId}` : ""
    }`,
  updateStaff: (client_id: string, staff_id = "") =>
    `/rest/clients/${client_id}/staff/${staff_id}`,
  removeStaff: (client_id: string, staff_id = "") =>
    `/rest/clients/${client_id}/staff/${staff_id}`,
  inputFormAnswers: {
    section: (ifa_id: string, idx: string) =>
      `/rest/input_form_answers/${ifa_id}/sections/${idx}/`,
  },
  tracking: "/rest/tracking",
  trackingBeacon: "/rest/tracking_beacon",
  alert_conditions: {
    index: ({ client_id }: { client_id: string }) =>
      `/rest/clients/${client_id}/alert_conditions`,
    create: ({ client_id }: { client_id: string }) =>
      `/rest/clients/${client_id}/alert_conditions`,
    update: ({ client_id, id }: { client_id: string; id: string }) =>
      `/rest/clients/${client_id}/alert_conditions/${id}`,
    destroy: ({ client_id, id }: { client_id: string; id: string }) =>
      `/rest/clients/${client_id}/alert_conditions/${id}`,
  },
  consents: {
    index: "/rest/consents",
    forUser: (id: string) => `/rest/consents/?person_id=${id}`,
    show: (id: string) => `/rest/consents/${id}`,
    offerable: (id: string) => `/rest/consents/offerable?person_id=${id}`,
  },
  embeddable_views: {
    index: `/rest/embeddable_views/`,
  },
  pushDevices: {
    index: `/rest/push_devices`,
  },
  treatment_programs: {
    index: () => `/rest/all_treatment_programs`,
    stubs: (domains: string[] | undefined) =>
      `/rest/treatment_programs/stubs?${stringify(
        { domains },
        {
          indices: false,
          arrayFormat: "brackets",
        },
      )}`,
  },
  treatment_program: {
    index: (programId: number) => `/rest/treatment_programs/${programId}`,
    addStaff: (programId: number) =>
      `/rest/treatment_programs/${programId}/staff/`,
    removeStaff: (programId: number, staffId: number) =>
      `/rest/treatment_programs/${programId}/staff/${staffId}`,
    addTeam: (programId: number) =>
      `/rest/treatment_programs/${programId}/hcp_teams`,
    removeTeam: (programId: number, teamId: number) =>
      `/rest/treatment_programs/${programId}/hcp_teams/${teamId}`,
  },
  client_treatment_programs: {
    index: (clientId: string) => `/rest/clients/${clientId}/treatment_programs`,
    show: (clientId: string, treatmentProgramId: string) =>
      `/rest/clients/${clientId}/treatment_programs/${treatmentProgramId}`,
    create: (clientId: string) =>
      `/rest/clients/${clientId}/treatment_programs`,
    destroy: (clientId: string, treatmentProgramId: string) =>
      `/rest/clients/${clientId}/treatment_programs/${treatmentProgramId}`,
    update_treatment_programs: (clientId: string) =>
      `/rest/clients/${clientId}/update_treatment_programs`,
    tasks: {
      index: (clientId: string, treatmentProgramId: string) =>
        `/rest/clients/${clientId}/treatment_programs/${treatmentProgramId}/tasks`,
      create: (clientId: string, treatmentProgramId: string) =>
        `/rest/clients/${clientId}/treatment_programs/${treatmentProgramId}/tasks`,
      update: (clientId: string, treatmentProgramId: string, task_id: string) =>
        `/rest/clients/${clientId}/treatment_programs/${treatmentProgramId}/tasks/${task_id}`,
      destroy: (
        clientId: string,
        treatmentProgramId: string,
        task_id: string,
      ) =>
        `/rest/clients/${clientId}/treatment_programs/${treatmentProgramId}/tasks/${task_id}`,
    },
  },
  patient_records: {
    index: "/rest/patient_records",
    display: (id: string | number) => `/rest/patient_records/${id}/display`,
    download: "/rest/exports/patient_records",
    share: `rest/patient_records/share`,
    activities: "rest/patient_records/activities",
  },
  urgentTasks: {
    index: "/rest/urgent_tasks",
  },
  login_authentications: {
    index: "/rest/login_authentications",
    request_code: "/rest/login_authentications/request_code",
    verify_code: "/rest/login_authentications/verify_code",
  },
  session_expiration: "/auth/expiration",
} as const;
