import React from "react";
import { injectIntl, IntlShape } from "react-intl";
import { CustomModal, CustomModalExtras } from "@netmedi/design-system";

export type Props = CustomModalExtras.BaseProps & {
  ariaDescriptionId?: string;
} & ({ ariaLabel: string } | { ariaLabelId: string });

const AccessibleModal = (props: Props & { intl: IntlShape }) => {
  const ariaLabel = "ariaLabel" in props ? props.ariaLabel : undefined;
  const ariaLabelId = "ariaLabelId" in props ? props.ariaLabelId : undefined;
  const computedAriaLabel = ariaLabelId
    ? props.intl.formatMessage({ id: ariaLabelId })
    : ariaLabel;
  const computedAriaDescription = props.ariaDescriptionId
    ? props.intl.formatMessage({ id: props.ariaDescriptionId })
    : props.ariaDescription;
  return (
    <CustomModal
      {...props}
      ariaLabel={computedAriaLabel}
      ariaDescription={computedAriaDescription}
    />
  );
};

export default injectIntl(AccessibleModal) as React.FC<Props>;
