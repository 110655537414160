import styled, { css } from "styled-components";
import rtl from "styled-components-rtl";
import rem from "@netmedi/design-system/dist/styles/styleHelpers";
import { mediaMin } from "@netmedi/design-system/dist/styles/mixins/mediaQueryMixins";

import {
  forms,
  colors,
  mediaQueries,
  scaffolding,
  input,
  zIndexes,
} from "@netmedi/design-system/dist/styles/variables";
import React from "react";

export const FORMFIELD_CHILD_CLASS = "Kaiku_FormField";
export const FORMFIELD_TOPLABEL_CLASS = "Kaiku_FieldTopLabel";

export const TopContent = styled.div(rtl`
  float: right;
  font-weight: normal;

  & * {
    margin: 0;
    font-size: ${rem(14)};
  }
`);

export const FieldRequired = styled.span`
  color: ${colors.red700};
`;

export const ErrorMsg = styled.div.attrs(() => ({
  "data-testid": "error-msg",
}))<{ labelTopLabel?: boolean }>`
  color: ${colors.red700};
  ${rtl`margin: 0.5em 0 0`};

  ${({ labelTopLabel }) =>
    !labelTopLabel &&
    css`
      ${mediaMin(mediaQueries.small)} {
        ${rtl`margin-left: 40%;`}
      }
    `}
`;

export const FormFieldInput = styled.input<{
  small?: boolean;
  error?: boolean;
  submitFailed?: boolean;
  touched?: boolean;
  passwordNeeded?: boolean;
}>`
  background: ${input.background};
  border: ${forms.borderColor} 1px solid;
  border-radius: ${forms.borderRadius}px;
  padding: 0.5em;
  transition: border 0.2s;
  display: block;
  min-height: 2.375rem;

  && {
    line-height: 1.25;
  }

  ${({ small }) => !!small && `width: 10em;`}
  ${({ error, submitFailed, touched }) =>
    !!error && (submitFailed || touched) && `border-color: "aquamarine";`}

  &:focus,
  &:active {
    outline: none;
    border-color: ${forms.borderColorActive};
    ${rtl`box-shadow: 0 0 0pt 2pt ${colors.blue50};`}
  }

  &:disabled {
    background: ${colors.gray50};
    color: ${colors.gray700};
    border-color: ${forms.borderColor};

    ${({ passwordNeeded }) => !!passwordNeeded && `color: ${colors.gray950};`}
  }

  select& {
    font-size: ${rem(16)};
    color: ${scaffolding.textColor};
    appearance: none;
    width: 220px;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.66 5.332 8 9.072 4.34 5.332a.75.75 0 1 0-1.072 1.05l4.196 4.286a.75.75 0 0 0 1.072 0l4.196-4.287a.75.75 0 1 0-1.072-1.049Z' fill='currentColor'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    ${rtl`background-position: right 0.5em top 50%;`}
    background-size: 1.15em;
  }
`;

export const FormFieldLabel = styled.div<{
  labelTopLabel?: boolean;
  labelWeight?: "bold" | "normal";
}>`
  display: block;
  line-height: 2.5em;
  font-size: ${rem(16)};

  ${({ labelWeight }) =>
    labelWeight === "bold" ? `font-weight: 500;` : `font-weight: normal;`}

  ${({ labelTopLabel }) =>
    labelTopLabel &&
    rtl`
    line-height: 1.5em;
  `}

  ${mediaMin(mediaQueries.small)} {
    ${({ labelTopLabel }) =>
      !labelTopLabel &&
      css`
        && {
          ${rtl`text-align: right;`}
        }
      `}
  }
`;

const fieldStyles = ({ labelWidth }: { labelWidth: number }) => css`
  line-height: 1.25;
  overflow: visible;
  ${rtl`text-align: left;`}
  width: 100%;
  font-weight: 400;

  label {
    display: block;
  }

  ${mediaMin(mediaQueries.small)} {
    &:not(.${FORMFIELD_TOPLABEL_CLASS}) {
      width: ${labelWidth ? 100 - labelWidth : 60}%;
      ${rtl`float: right;`}
      ${rtl`margin-left: 1.5em;`}
    }
  }

  &[type="email"] {
    ${rtl`text-align: left;`}
    ${rtl`direction: ltr;`}
  }
`;

export const Wrapper = styled.div<{
  wrapTopLabel?: any;
  error?: any;
  submitFailed?: boolean;
  touched?: boolean;
  labelWidth?: number;
}>`
  padding: 0.5em 0;
  width: 100%;
  clear: both;
  font-size: ${rem(16)};

  ${({ wrapTopLabel }) => wrapTopLabel && rtl`float: right;`}

  ${FormFieldInput}, .${FORMFIELD_CHILD_CLASS} {
    ${({ error, submitFailed, touched }) =>
      !!error && (submitFailed || touched) && `border-color: ${colors.red500};`}
  }

  input[type="tel"] {
    ${({ error, submitFailed, touched }) =>
      !!error && (submitFailed || touched) && `border-color: ${colors.red500};`}
  }

  .${FORMFIELD_CHILD_CLASS} {
    ${({ labelWidth }) => fieldStyles({ labelWidth: labelWidth ?? 40 })}
  }
`;

export const AddOn = styled.div.attrs(() => ({
  "data-testid": "add-on-field",
}))``;

export const AddOnInput = styled.div.attrs(() => ({
  "data-testid": "add-on-input",
}))(rtl`
  line-height: 1.25;
  position: relative;

  ${FormFieldInput} {
    width: 7em;
    border-radius: ${input.borderRadius}px;
    z-index: ${zIndexes.inputAddon};
    float: left;
    margin-bottom: 0;
    padding: 8px;
  }

  ${FormFieldInput},
  ${AddOn} {
    display: inline-block;
  }

  ${AddOn} {
    padding: 0.5em;
    color: ${scaffolding.textColor};
    white-space: nowrap;
    position: relative;
  }

  ${FormFieldInput}[type=date] {
    width: auto;
    max-width: 15em;
  }

  ${FormFieldInput}[type=time] {
    width: auto;
    max-width: 8em;
  }
`);

export const DateInput = styled(AddOnInput).attrs(() => ({
  "data-testid": "date-input",
}))<{ fullWidth?: boolean }>`
  display: inline-block;

  ${FormFieldInput} {
    width: 9em;
  }

  ${({ fullWidth }) =>
    fullWidth &&
    `
    width: 100%;
    max-width: 100%;

    ${FormFieldInput}[type=date] {
      width: 100%;
      max-width: 100%;
    }
  `}
`;

export const StyledErrors = styled.ul.attrs(() => ({
  "data-testid": "errors",
  role: "alert",
  "aria-atomic": true,
  "aria-live": "polite",
}))(rtl`
  color: ${colors.red500};
  margin-top: 10px;
  margin-bottom: 0;
  padding-left: 20px;
`) as React.FC<React.HTMLProps<HTMLUListElement>>;

type StyledErrorProps = { first?: boolean };
export const StyledError = styled.p.attrs(({ first }: StyledErrorProps) => ({
  "data-testid": "error",
  role: "alert",
  "aria-live": first ? "assertive" : "polite",
  "aria-atomic": "true",
}))<StyledErrorProps>`
  color: ${colors.red700};
  margin: 0;
  padding: 0;
`;

export const DOBWrapper = styled.div`
  span {
    padding: 0;
  }
`;

export const DatePickerInputContainer = styled.div`
  width: 60%;
  display: flex;
  align-items: center;
`;

export const SelectInputContainer = styled.div`
  width: 60%;
`;
